import React from "react";

import {connect} from "react-redux";
import {checkPermissions} from "./functions";

class PermissionHidden extends React.Component {

    render() {
        const {permissions = []} = this.props

        if (checkPermissions(permissions)) {
            return this.props.children
        }

        return (<div/>)
    }
}

const mapStateToProps = (state => {
    return {
        access: state.auth.access
    }
})

export default connect(mapStateToProps)(PermissionHidden)
